(function ($) {
    'use strict';

    $.fn.persons = function() {
        var _persons = $(".c-person__items .c-grid__item");
        var _personsDetail = $(".c-person li");

        _persons.on("click", function(){
            var _this = $(this);
            var _personDetail = _personsDetail.filter("[data-person='" + _this.attr("data-person") + "']");

            $("[data-person].is--active").hide(500, function() {
                _personsDetail.removeClass("is--active");
                _personDetail.show(500, function(){
                    _personDetail.addClass("is--active");
                });
            });
        });
    };
})(jQuery);
