(function ($) {
    'use strict';

    $.fn.menu = function() {
        var _menu = $(".c-menu");
        var _button = $(".e-hamburger");
        var control = "is--visible";
        var active = "is--active";

        _button.on("click", function() {
            console.log(_button);
            if (_menu.hasClass(control)) {
                _menu.removeClass(control);
                _button.removeClass(active);
            }else {
                _menu.addClass(control);
                _button.addClass(active);
            }
        });
    };
})(jQuery);
