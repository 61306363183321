(function ($) {
    'use strict';

    $.fn.initSiteTransition = function() {
        var body = $('body');
        var newLocation;
        var duration = 600;

        body.animate({ opacity: 1 }, duration, 'swing', refresh);

        $('a').on('click', function (e) {
            e.preventDefault();

            var value = this.getAttribute('href');
            var noAction = checkNoAction(this.href);
            newLocation = this.href;

            if (value !== '#' && noAction && (value.trim().length > 1 || value === '/') && !$(this).hasClass('js--notransition')) {
                body.animate({ opacity: 0 }, duration, 'swing', newpage);
            }
        });

        // load new page
        function newpage() {
            window.location = newLocation;
        }

        function refresh() {
            window.onpageshow = function(event) {
                if (event.persisted) {
                    window.location.reload();
                }
            };
        }

        function checkNoAction(href) {
            var status = true;
            ['tel:', 'mailto:', 'fax:'].forEach(function (action) {
                if (href.indexOf(action) >= 0) {
                    status = false;
                }
            });
            return status;
        }
    };
})(jQuery);
