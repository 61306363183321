var Site = {

    init : function() {
        this.domReady();
    },

    domReady : function() {
        $(document).ready(function() {
            $().menu();
            $().persons();
            $().initSiteTransition();
        });
    }
};

// Init
Site.init();